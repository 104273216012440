.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  user-select: none;
}

.policies {
  background-color: #fff;
  padding: 50px 200px;
}

.policies ul {
  list-style: disc;
}

.header-hr {
  height: 10px;
  box-shadow: 0 1px 1px -1px #fff inset;
}

.home-sec-wid {
  padding: 0px 100px 100px;
}

.remove-pb-100 {
  padding-bottom: 0px !important;
}

.get-certified {
  display: flex;
  align-items: center;
  gap: 20px;
}

.get-certified p {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
}

.get-certified .certified-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.get-certified .certified-by img {
  width: 100px;
  height: auto;
}

.home-sec-wid .h2 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--lightTheme);
  line-height: 1.2;
  margin-bottom: 20px;
}

.footer {
  margin-top: auto;
  color: #fff;
  background-color: #232323;
}

/* New */

.home-main-section {
  display: flex;
  align-items: flex-start;
}

.home-top-section .moto {
  color: var(--blackTheme);
}

.sidebar-section .divider {
  width: 50%;
  margin: 0 auto 70px;
  background: linear-gradient(
    to right,
    var(--secondaryTheme),
    #f38f3d,
    var(--secondaryTheme)
  );
  height: 4px;
}

.home-top-section.bottom .right {
  position: relative;
}

.home-top-section.bottom .right img {
  position: absolute;
  top: -80px;
  right: 220px;
  width: 300px;
  transform: scaleX(-1);
}

.sidebar-section .h2 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 800;
  margin-top: 20px;
}

.sidebar-section .plus-why-dm-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin: 20px 0;
  gap: 20px;
}

.sidebar-section .plus-why-dm-container img {
  height: 350px;
  width: 350px;
}

.sidebar-section .plus-why-dm {
  background-color: var(--secondaryTheme);
  padding: 10px 20px;
  border-radius: 5px;
}

.plus-program-highlights-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.plus-program-highlights-container .program-highlights {
  background-color: var(--lightBlueTheme);
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn-outlined-theme {
  border: 1px solid var(--blackTheme) !important;
  color: var(--blackTheme) !important;
}

.sidebar-section .plus-why-dm p {
  line-height: 1.8;
}

.sidebar-section .h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-left: 20px;
}

.sidebar-section .h3-alt {
  font-size: 1.3rem;
  font-weight: 600;
}

.m-10-neg {
  margin-top: -10px !important;
}

.home-sidebar {
  padding: 20px;
  margin: 20px 50px;
  color: #555;
}

.active-scroll-spy {
  color: var(--primaryTheme);
  font-weight: 600;
  font-size: 1.1em;
}

.program-highlights {
  display: flex;
  width: 250px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.program-highlights .h3-alt.center {
  text-align: center;
}

.program-highlights .h3-alt.center ~ p {
  text-align: center;
}

.home-sidebar ul li:not(:last-child) {
  list-style: none;
  margin-bottom: 10px;
}

.home-sidebar ul li {
  font-weight: 400;
  font-size: 1.05em;
}

.sidebar-section {
  padding-bottom: 50px !important;
}

.sidebar-section:first-child {
  margin-top: 20px;
}

.plus-main {
  width: 70%;
}

.home-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 250px 50px 110px;
}

.home-top-section.landing-page {
  padding: 100px 110px 50px;
  justify-content: space-around;
}

.home-top-section.landing-page .right .right-inner,
.home-top-section.landing-page .right .right-inner form div {
  display: flex;
  flex-direction: column;
}

.home-top-section.top.landing-page .right {
  width: 35%;
}

.home-top-section.landing-page .right .right-inner {
  border: 1px solid var(--secondaryTheme);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--darkTheme);
  color: var(--lightTheme);
}

.home-top-section.landing-page .right .right-inner .box-form p {
  margin-bottom: 15px;
  font-size: 1.2em;
  font-weight: 600;
}

.companies-landing {
  display: flex;
  justify-content: space-between;
  padding: 50px 0px;
  gap: 40px;
}

.companies-landing .left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.companies-landing .left p {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
}

.companies-landing .left .h2 {
  text-align: left;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
}

.companies-landing .right .companies {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  justify-items: center;
}

.home-top-section.landing-page .right .right-inner form input[type="text"],
.home-top-section.landing-page .right .right-inner form input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.home-top-section.landing-page .right .right-inner form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.home-top-section.top {
  background-color: #fff;
}

.home-top-section.bottom {
  background-color: var(--lightBlueTheme);
}

.home-top-section.bottom.landing-page-color {
  background-color: var(--darkTheme);
  margin: 20px 0;
  padding: 50px 50px 50px 150px;
}

.home-top-section.bottom.landing-page-bottom {
  padding: 20px;
  margin-left: 50px;
  flex-direction: column;
}

.home-top-section.bottom.landing-page-bottom .left {
  max-width: 100%;
}

.home-top-section h1 {
  font-size: 45px;
  font-weight: 700;
  line-height: 106%;
  color: #0c2343;
}

.home-top-section h1 span {
  color: var(--primaryTheme);
}

.home-top-section .icons-info svg {
  color: var(--primaryTheme);
}

.home-top-section.top .left,
.home-top-section.bottom .left {
  max-width: 55%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home-top-section.top .right {
  position: relative;
}

#name {
  grid-area: name;
}

#email {
  grid-area: email;
}

#code {
  grid-area: code;
}

#phone {
  grid-area: phone;
}

#freeDemo {
  grid-area: freeDemo;
}

.isoffersyou {
  background-color: var(--darkTheme);
}

.isoffersyou .h2 {
  margin-bottom: 50px;
  padding-top: 50px;
}

.home-top-section.bottom .input {
  display: grid;
  grid-template-columns: 65px 230px 185px;
  column-gap: 5px;
  row-gap: 15px;
  grid-template-areas:
    "email email email"
    "code phone freeDemo";
}

.home-top-section.bottom .input-landing {
  display: grid;
  grid-template-columns: 65px 115px 115px 185px;
  column-gap: 5px;
  row-gap: 15px;
  grid-template-areas:
    "name name email email"
    "code phone phone freeDemo";
}

.home-top-section.top .right .inner {
  position: absolute;
  top: -160px;
  right: -125px;
}

span.live {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  animation: blink 1s infinite;
}

span.live-off {
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.home-top-section.top .right .inner iframe {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.home-top-section.top .right .inner .video {
  border-radius: 10px;
  /* background-color: var(--primaryTheme); */
  background-color: #000;
}

.home-top-section.top .right .inner .video iframe {
  width: 480px;
  height: 360px;
}

.home-top-section.top .right .inner .video .title {
  padding: 10px 20px 5px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.home-top-section.top .right .inner .video .subtitle {
  padding: 5px 20px 10px;
  font-size: 0.8rem;
  color: #fff;
}

.home-top-section.top .left .icons-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.home-top-section.top .left .icons-info div {
  background-color: var(--lightTheme);
  /* box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75); */
  border: 2px solid var(--darkTheme);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding: 0.75rem;
}

.home-top-section.bottom .left h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #0c2343;
}

.home-top-section.bottom.landing-page-color .left h2,
.home-top-section.bottom.landing-page-color .left p {
  color: var(--lightTheme);
}

.home-top-section.bottom .left p {
  color: #1a202c;
  font-size: 18px;
}

.home-sidebar-mobile {
  display: none;
}

.plus-calc-slider {
  width: 50%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-vertical-center svg {
  font-size: 24px;
  color: #f38f3d;
}

.trainers-cards,
.student-cards {
  display: grid;
  gap: 20px;
  justify-content: center;
}

.trainers-cards {
  grid-template-columns: repeat(1, 215px);
}

.student-cards {
  grid-template-columns: repeat(4, 215px);
}

.trainers-cards .trainers-card,
.student-cards .student-card {
  background-color: #fff;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 10px;
  transition: all 0.1s ease-in-out;
}

.trainers-card .trainers-card-image,
.student-card .student-card-image {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.student-cards .student-card .logo {
  width: 180px !important;
}

.trainers-cards .trainers-card {
  background-color: var(--lightBlueTheme);
  cursor: pointer;
}

.trainers-cards .trainers-card:hover {
  transform: scale(1.02);
}

.trainers-cards .trainers-card img,
.student-cards .student-card img {
  width: 100px;
  height: auto;
}

.br-50 {
  border-radius: 50%;
}

.main-testimonials.home {
  display: grid;
  grid-template-columns: 5fr 7fr;
  padding-right: 10px;
}

.main-testimonials .review {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
}

.main-testimonials .testimonials {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.main-testimonials .testimonials .testimonial {
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
}

.main-testimonials .testimonials .testimonial .top {
  display: flex;
  gap: 20px;
  align-items: center;
}

.main-testimonials .testimonials .testimonial {
  font-size: 0.8rem;
}
.main-testimonials .testimonials .testimonial .top .details {
  font-size: 1.5em;
}

.main-testimonials .testimonials .testimonial .top img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.main-testimonials .testimonials .testimonial a {
  color: primaryTheme;
  text-decoration: underline;
  font-size: 0.8rem;
}

.main-testimonials.home .testimonials {
  margin: 10px;
}

.student-cards .student-card .name {
  font-size: 1.2rem;
}

.student-cards .student-card .college {
  font-size: 1rem;
}

/* -------------------------------------------- Other Devices -------------------------------------------- */

@media only screen and (max-width: 1500px) {
  .home-top-section.top .right .inner {
    position: absolute;
    top: -160px;
    right: -175px;
  }

  .home-top-section.bottom .right img {
    right: 100px;
  }

  .student-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .student-cards.landing {
    grid-template-columns: repeat(4, 1fr);
  }

  .hide-in-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 1280px) {
  .home-top-section.bottom .right img {
    right: 40px;
  }

  .home-top-section.top .left h1 {
    font-size: 2.5rem;
  }

  .get-certified {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .home-top-section.bottom .right img {
    display: none;
  }

  .home-top-section.top.landing-page .right {
    width: 90%;
  }

  .policies {
    padding: 50px;
  }
  .home-sidebar-mobile {
    display: block;
    width: 100%;
    position: sticky;
    padding-bottom: 15px;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    background-color: #fafafa;
  }

  .isoffersyou {
    padding: 50px 10px;
  }

  .main-testimonials.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* scroll y off */
    overflow-y: hidden;
  }

  .main-testimonials.home .testimonials .testimonial,
  .main-testimonials.home .review {
    width: 300px;
  }

  .main-testimonials.home .review {
    text-align: center;
  }

  .sidebar-section .divider {
    width: 75%;
  }

  .trainers-cards .trainers-card,
  .student-cards .student-card {
    width: 90%;
  }

  .home-main-section {
    flex-direction: column;
    align-items: center;
  }

  .home-sidebar {
    display: none;
  }

  .home-top-section.top .right .inner {
    position: static;
    margin-top: 50px;
  }

  .home-top-section {
    flex-direction: column;
  }

  .trainers-cards,
  .student-cards {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .sidebar-section .divider {
    width: 75%;
  }

  .trainers-cards .trainers-card,
  .student-cards .student-card {
    width: 90%;
  }

  .sidebar-section .plus-why-dm-container {
    grid-template-columns: 1fr;
  }

  .sidebar-section .plus-why-dm-container img {
    height: 300px;
    width: 300px;
  }

  .main-testimonials .testimonials {
    grid-template-columns: 1fr;
  }

  .companies-landing {
    display: flex;
    justify-content: space-between;
    padding: 50px 110px 50px;
    gap: 40px;
  }

  .companies-landing .left {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .companies-landing .left p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .companies-landing .left .h2 {
    text-align: left;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .companies-landing .right {
    width: 40%;
  }

  .companies-landing .right .companies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    justify-items: center;
  }

  /* Adjust */

  .companies-landing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    gap: 40px;
  }

  .companies-landing .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .companies-landing .left p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
  }

  .companies-landing .left .h2 {
    text-align: left;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  .companies-landing .right {
    width: 100%;
  }

  .companies-landing .right .companies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    justify-items: center;
  }
}

/* -------------------------------------------- Mobile -------------------------------------------- */

@media only screen and (max-width: 768px) {
  .home-top-section.top .right .inner .video iframe {
    width: 100%;
    height: auto;
  }

  .student-cards .student-card .logo {
    width: 120px !important;
  }

  .student-cards .student-card {
    gap: 10px;
  }

  .student-cards .student-card .name {
    font-size: 0.9rem;
  }

  .student-cards .student-card .college {
    font-size: 0.7rem;
  }

  .student-card .student-card-image {
    width: 60px;
    height: 60px;
  }

  .program-highlights .h3-alt.center {
    font-size: 1em;
  }

  .program-highlights {
    width: 125px;
    padding: 5px !important;
    gap: 5px !important;
  }

  .sidebar-section .plus-why-dm-container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .sidebar-section .plus-why-dm-container img {
    width: 250px;
    height: 250px;
  }

  .sidebar-section .h2 {
    font-size: 1.5em;
    white-space: nowrap;
  }

  .home-top-section.bottom {
    border: 1px solid var(--primaryTheme);
    margin: 0 20px 20px;
    padding: 50px 10px;
  }

  .home-top-section.top.landing-page .right {
    margin-top: 30px;
  }

  .home-top-section.top.landing-page .right .input-landing {
    grid-template-columns: 3fr 9fr;
    grid-template-areas:
      "name name"
      "email email"
      "code phone"
      "freeDemo freeDemo";
  }

  .home-top-section.landing-page {
    padding: 80px 10px;
  }

  .home-top-section.bottom.landing-page-bottom {
    margin: 0;
  }

  .home-top-section.bottom #freeDemo {
    margin: 0 auto;
  }

  .home-top-section.top .left h1 {
    font-size: 2rem;
  }

  .home-top-section.top .left h1 .block {
    display: block;
  }

  .program-highlights .h3-alt.center ~ p {
    display: none;
  }

  .home-top-section.top .right .inner {
    position: static;
    margin-top: 50px;
  }

  .home-top-section.top .right {
    width: 300px;
  }

  .home-sidebar {
    display: none;
  }

  .home-top-section {
    flex-direction: column;
  }

  .trainers-cards,
  .student-cards {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

  .sidebar-section .divider {
    width: 75%;
  }

  .trainers-cards .trainers-card,
  .student-cards .student-card {
    width: 80%;
  }

  .home-main-section {
    flex-direction: column;
    align-items: center;
  }

  .home-top-section {
    flex-direction: column;
    padding: 20px 0px;
  }

  .home-top-section h1 {
    font-size: 36px;
    text-align: center;
  }

  .home-top-section.top .left,
  .home-top-section.bottom .left {
    max-width: 90%;
  }

  .home-top-section.top .left .icons-info {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }

  .sidebar-section {
    padding: 20px 10px;
  }

  .plus-main {
    width: 90%;
  }

  .plus-calc-slider {
    width: 75%;
  }

  .center-txt-resp {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .plus-program-highlights-container {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .MuiBox-root.css-19kzrtu {
    padding: 0%;
  }

  .home-top-section.bottom .input {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas:
      "email email"
      "code phone"
      "freeDemo freeDemo";
  }
}

.home-top-section.bottom.landing-page-color .left p {
  margin-bottom: 10px;
}

/* Companies Start */

.companies {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  justify-items: center;
}

.companies img {
  height: 50px;
  cursor: pointer;
  transition: all ease 0.1s;
}

.companies img:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 1500px) {
  .companies {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .companies {
    gap: 20px;
  }
  .companies img {
    width: 75px;
    height: auto;
  }

  .home-top-section.bottom.landing-page-color {
    padding: 50px 0;
    flex-direction: column-reverse;
  }

  .home-top-section.bottom.landing-page-color .left h2,
  .home-top-section.bottom.landing-page-color .left p {
    text-align: center;
  }
}

/* Companies End */

/* Card Start */

.slick-placement-card {
  display: flex !important;
  margin: 20px auto 40px !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 250px !important;
  height: 420px !important;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgb(0 0 0 / 75%);
  transition: all ease 0.2s;
}

.slick-placement-card:hover {
  transform: scale(1.05);
}

.slick-placement-card .slick-placement-card-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
}

.slick-testimonails-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgb(0 0 0 / 75%);
  font-size: 12px;
  width: 250px !important;
  margin: 20px !important;
}

.slick-testimonails-card .top {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.slick-testimonails-card .top img {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
}

.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots.slick-thumb li {
  width: 10px;
  height: 10px;
  margin: 0 3px;
  border-radius: 999px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color: black;
}

.slick-dots.slick-thumb li.slick-active {
  background-color: var(--primaryTheme);
  width: 24px;
}

@media only screen and (max-width: 1024px) {
  .slick-dots {
    bottom: 0 !important;
  }
}

/* Card End */
