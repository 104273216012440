.home {
  max-width: 100vw !important;
  overflow-x: hidden;
}

.sidebar-section {
  padding: 20px 100px !important;
}

@media only screen and (min-width: 1024px) {
  .review .h3-alt {
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 1024px) {
  .sidebar-section {
    padding: 20px 100px !important;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar-section {
    padding: 20px 20px !important;
  }
}
