/* :root {
  --primaryTheme: #0056d2;
  --secondaryTheme: #fce3ce;
  --lightTheme: #ffffff;
  --lightBlueTheme: #f0f6f9;
  --lightDarkTheme: #003a55;
  --darkTheme: #002333;
} */

:root {
  --primaryTheme: #ef740d;
  --secondaryTheme: #fce3ce;
  --lightTheme: #ffffff;
  --lightBlueTheme: #f0f6f9;
  --lightDarkTheme: #003a55;
  --lightGreenTheme: #00bfa5;
  --darkTheme: #002333;
  --blackTheme: #000;
}

.primaryBackground {
  background-color: var(--primaryTheme) !important;
}

.primaryText {
  color: var(--primaryTheme) !important;
}

.secondaryBackground {
  background-color: var(--secondaryTheme) !important;
}

.secondaryText {
  color: var(--secondaryTheme) !important;
}

.lightText {
  color: var(--lightTheme) !important;
}

.lightBlueBackground {
  background-color: var(--lightBlueTheme) !important;
}

.lightDarkBackground {
  background-color: var(--lightDarkTheme) !important;
}

.darkBackground {
  background-color: var(--darkTheme) !important;
}

.lightGreenBackground {
  background-color: var(--lightGreenTheme) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  background-color: #fafafa;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryTheme);
}
