.thank-you-container {
  display: flex;
  flex-direction: column;
  margin: 60px auto 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 768px;
}

.thank-you-container .thank-you-text {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.03);
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.thank-you-container .thank-you-text p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.thank-you-container .thank-you-text svg {
  fill: #4bb543;
  font-size: 25px;
}

.thank-you-container .information .h2 {
  font-size: 1.6rem;
  font-weight: 800;
  width: 75%;
  text-align: center;
  margin: 0 auto 20px;
}

.thank-you-container .information .info {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.thank-you-container .information .info .info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.thank-you-container .information .info .info-item .info-title {
  font-weight: 600;
  color: #888;
}

.thank-you-container .information .info .info-item .info-value {
  font-weight: 800;
  color: var(--primaryTheme);
  font-size: 22px;
}

.thank-you-testimonials {
  font-weight: 800;
  font-size: 20px;
  padding: 0 15px;
}

.thank-you-container .placements {
  padding: 0 15px 20px;
}

.thank-you-container .placements2 {
  padding: 15px 20px;
  text-align: center;
}

.thank-you-container .placements .placements-title {
  border-bottom: 1px solid #ccc;
}

.thank-you-container .placements .placements-title p {
  display: inline;
  border-bottom: 3px solid var(--primaryTheme);
  font-weight: 800;
  font-size: 20px;
  color: var(--primaryTheme);
}

.thank-you-container .placements .students {
  margin-top: 20px;
}

.thank-you-container .placements .student {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
}

.thank-you-container .placements .student:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
}

.thank-you-container .placements .student:last-child {
  margin-bottom: 30px;
}

.thank-you-container .placements .student img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.thank-you-container .placements .student .student-data {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.thank-you-container .placements .student .student-data .student-company {
  color: rgb(55, 113, 216);
  font-weight: 800;
}
