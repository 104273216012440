.footer {
  font-size: 0.9em;
  padding-bottom: 30px;
}

.footer-inner {
  margin: 50px 200px;
}

.footer-inner .identity {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.footer-inner .identity .footer-social svg {
  font-size: 32px;
  margin-right: 15px;
  color: #fff !important;
}

.footer-inner li {
  margin-top: 10px;
}

.footer-top {
  display: grid;
  grid-template-columns: 7fr 3fr 2fr;
  column-gap: 50px;
}

.footer .contact-inner {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.footer .contact-inner svg {
  color: #fff;
}

.footer .divider {
  width: 75%;
  margin: 0 auto;
  background: linear-gradient(
    to right,
    var(--secondaryTheme),
    #fff,
    var(--secondaryTheme)
  );
  height: 2px;
}

/* Bottom */

.footer-bottom {
  display: grid;
  grid-template-columns: 6fr 4fr 2fr;
  column-gap: 0px;
}

.footer-bottom .cki .cki-inner,
.footer-bottom .sjg .sjg-inner {
  display: flex;
  gap: 50px;
}

.footer h3 {
  font-size: 20px;
  font-weight: 700;
}

/* End */

.footer-end {
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-end div {
  display: flex;
  gap: 20px;
}

.footer-end {
  margin-bottom: 75px;
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .footer-inner {
    margin: 50px 50px;
  }

  .footer-top {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }

  .footer .contact-inner svg {
    margin-left: -35px;
  }

  .footer .divider {
    width: 100%;
  }

  .footer-bottom {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }

  .footer-bottom {
    text-align: left !important;
  }

  .footer-bottom .cki .cki-inner {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .footer-end div {
    flex-direction: column;
    gap: 10px;
  }

  .footer-end {
    margin-bottom: 75px;
  }
}
