.ctamodal {
  padding: 20px;
  display: flex;
  text-align: center;
}

.ctamodal .left ul,
.ctamodal .left p {
  text-align: left;
}

.ctamodal .left small {
  color: #555;
}

.ctamodal .left p {
  font-weight: 400;
  color: #1e3658;
  font-size: 16px;
  margin: 10px 0;
}

.ctamodal .left ul li {
  font-size: 14px;
  margin: 5px 0;
}

.ctamodal .left {
  border-right: 2px solid #555;
  padding-right: 50px;
}

.ctamodal .right {
  padding-left: 50px;
}

.ctamodal .left,
.ctamodal .right {
  flex: 1;
}

.ctamodal .right form {
  display: flex;
  flex-direction: column;
  margin: 0 auto 10px;
  width: 350px;
}

.ctamodal .right small {
  font-size: 10px;
  color: #555;
}

.ctamodal .right small a {
  color: blue;
  text-decoration: underline;
}

@media only screen and (max-width: 1024px) {
  .ctamodal {
    flex-direction: column;
  }
  .ctamodal .left {
    display: none;
  }
  .ctamodal .right {
    padding-left: 0;
  }

  .ctamodal .right form {
    width: 100%;
  }
}
