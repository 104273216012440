.footer-fixed-inner {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--lightBlueTheme);
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 200px;
  z-index: 999;
}

.footer-fixed-inner p {
  font-size: 1.3em;
  color: var(--darkTheme);
}

.footer-fixed-inner button {
  font-size: 1.1em;
}

.footer-fixed-inner .arrow {
  position: relative;
}

.footer-fixed-inner .arrow img {
  position: absolute;
  width: 200px;
  height: 150px;
  top: -100px;
  left: -160px;
}

.footer-fixed-inner.landing {
  justify-content: center;
  height: 50px;
}

/* Other sizes */

@media only screen and (max-width: 1496px) {
  .footer-fixed-inner {
    padding: 10px 150px;
  }

  .footer-fixed-inner .arrow img {
    left: -160px;
    width: 150px;
  }
}

@media only screen and (max-width: 1024px) {
  .footer-fixed-inner {
    padding: 10px 100px;
  }

  .footer-fixed-inner button {
    font-size: 0.9em;
  }
  .footer-fixed-inner button svg {
    font-size: 1.5em !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer-fixed-inner .arrow img {
    display: none;
  }

  .footer-fixed-inner {
    padding: 10px;
    justify-content: center;
    gap: 10px;
  }

  .footer-fixed-inner p.h3 {
    display: none;
  }
}
